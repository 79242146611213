.container-card {
  display: grid;
  grid-row-gap: 2em;
  min-width: 70%;
	max-width: 1280px;
  height: auto;
  margin-top: 4em;
	margin-left: auto;
	margin-right: auto;
	padding: 2em;
}