.container-card {
  padding: 2em;
  display: grid;
  grid-row-gap: 4em;
}

.work-table {
  width: 75%;
}

.delete-button {
  cursor: pointer;
}

.detail-text {
  font-size: 70%;
  width: 75%;
}

.agree-text {
  margin-top: 2em;
  font-size: 80%;
  font-weight: bold;
}
