body {
  color: #707070;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

.button-fando {
  margin: 4px 8px;
  background-color: white;
  font-size: 80%;
  color: #707070;
  border: 0;
  box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.15);
}

.button-fando:hover {
  color: #707070;
  border: 0;
  box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.30);
}

.button-highlight-valid {
  border: 1px solid  rgba(129, 186, 26, .8);
  padding: -1px;
}

.button-highlight-valid:hover {
  border: 1px solid  rgba(129, 186, 26, .8);
  padding: -1px;
}


:global .show>.btn-primary.dropdown-toggle {
  background-color: #eee;
  color: black;
}

.button-small {
  color: #9C9C9C;
  font-size: 60%;
  padding: 4px 8px;
  margin-top: .25em;
  box-shadow: .5px 1px 2px 0px rgba(0,0,0,0.10);
}

.button-small:hover {
  color: #707070;
  box-shadow: .5px 1px 2px 0px rgba(0,0,0,0.15);
}

.dash-container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 8em auto;
  grid-template-columns: 2fr 8fr 2fr;
}

.header-text {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  padding: 2em 4em;
}

.above-card-text {
  margin: 8px;
  display: inline-flex;
}

.above-card-text > svg {
  align-self: center;
  margin: 4px 4px 0px 16px;
}


.above-card-text h4 {
  align-self: center;
  margin: 4px 8px 0px 2px;
}

.above-card-text .button-fando {
  align-self: center;
}

.above-card-text .button-fando svg {
  font-size: 1.5em;
  margin: 0px 4px 0px -4px;
}

.guitar-icon {
  color: rgba(129, 186, 26, .8)
}

.music-icon {
  margin-right: 1em;
}

.record-icon {
  color: rgba(0,0,0,0.8)
}

.card-container {
  grid-row-start: 2;
  grid-column-start: 2;
  min-width: 900px;
}

.main-card {
  border: 0;
  border-radius: 5px;
  box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.25);
  padding: 2em 4em;
}

.project-row {
  display: grid;
  padding: 0;
  grid-template-columns: 2em 5fr 1fr 1fr 2fr;
  background-color: white;
}

.border-top {
  padding-top: 1em;
  border-top: 1px solid #EEE;
  margin-bottom: 1em;
}

.light-header-text {
  color: #9C9C9C;
}

.accordion-card {
  border: 0;
}

.chevron {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #888;
  margin: 0 .25em 0 0;
  cursor: pointer;
}

.bold-project-text {
  display: inline-flex;
  font-size: 120%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold-project-text div {
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.bold-project-text button {
  margin-left: 1em;
}


.project-row-text {
  font-size: 120%;
}

.release-card {
  border: 0;
  border-radius: 5px;
  background-color: #fcfcfc;
  box-shadow: inset 1px 2px 4px 0px rgba(0,0,0,0.15);
  padding: 1em 2em 0 2em;
  margin-bottom: 2em;
  display: grid;
  grid-template-columns: 42px 42px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1em;
}

.release-art-container {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  transition-delay: .5s;
  transition: all .2s;
  cursor: pointer;
}


.release-art-container img {
  border: 1px solid #CCC;
  box-shadow: 2px 4px 8px 0px rgba(0,0,0,0.25);
  animation: artmove 1s;
  animation-fill-mode: forwards;
}

@keyframes artmove {
  0% {transform: scale(.9)}
  100% {margin-top: -1.5em;}
}

.bold-release-text {
  grid-column: 3 / 9;
  display: inline-flex;
  font-size: 120%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold-release-text div {
  max-width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.bold-release-text button {
  margin-left: 1em;
  height: 2em;
}

.release-track-count {
  grid-column: 3 / 9;
  font-size: 75%;
  color: #9C9C9C;
  margin-bottom: 3em;
  margin-left: -1.2em;
  cursor: pointer;
}

:global .file-drop {
  /* relatively position the container bc the contents are absolute */
  background: white;
	border: 1px dashed #bbb;
	width: 320px;
	height: 50px;
	padding: 20px;
	text-align: center;
	color: #888;
  position: relative;
  cursor: default;
}

:global .file-drop > .file-drop-target {
  /* basic styles */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  /* --------------- */

  /* horizontally and vertically center all content */
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;

  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;

  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;

  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;

  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;

  text-align: center;
  /* --------------- */
}

:global .file-drop-target.file-drop-dragging-over-frame {
  /* overlay a black mask when dragging over the frame */
  border: none;
  background-color: rgba(0, 0, 0, 0.45);
  box-shadow: none;
  z-index: 50;
  opacity: 1.0;
  /* --------------- */

  /* typography */
  color: white;
  /* --------------- */
}


.work-list {
  grid-column: 2 / 11;
  width: 100%;
}

.work-row {
  display: grid;
  font-size: 95%;
  grid-template-columns: 42px 1fr 4fr 1fr 1fr 1fr 1fr 1fr;
}

.work-row-header {
  width: 100%;
  display: grid;
  grid-template-columns: 42px 1fr 4fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 75%;
  color: #BBB;
}

.work-price {
  font-weight: bold;
}

.split-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.split-dots {
  display: inline;
  line-height: 4px;
  font-size: .8em;
  padding: 1px 2px;
  border: 1px solid #ccc;
  border-radius: 50px;
  cursor: default;
}

.td-play-button {
  cursor: pointer;
}

:global .modal-backdrop.show {
  opacity: .15;
}

:global .modal-content {
  border: 0;
}

:global .modal.fade .modal-dialog {
  transform: scale(.95)
}

:global .modal.show .modal-dialog {
  transform: scale(1)
}


.modal-general {
  padding: 1em 1.5em;
  box-shadow: 2px 4px 46px -2px rgba(0,0,0,0.05);
  border: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.modal-general ::placeholder {
  color: #aaa;
}

.modal-header {
  grid-column: 1 / 12;
  display: flex;
  justify-content: flex-end;
}

.modal-header div {
  flex: 6;
  display: inline-flex;
}

.project-name {
  display: block;
  grid-column: 1 / 6;
}

.modal-body {
  grid-row-start: 2;
  grid-column: 1 / 12;
  display: grid;
  grid-template-columns: inherit;
  font-size: 80%;
}

.modal-footer {
  grid-row-start: 3;
  grid-column: 1 / 12;
  display: flex;
  justify-content: flex-end;
  border: none;
}

.form {
  grid-column: 1 / 12;
  display: grid;
  grid-template-columns: inherit;
}

.left-pane {
  grid-column: 1 / 7;
  grid-column-gap: 1em;
  padding-right: 1em;
  border-right: 1px solid #EEE;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}


.left-pane label {
  grid-column: 1 / 7;
}


.right-pane {
  grid-row-start: 1;
  grid-column: 7 / 12;
  padding-left: 1em;
}

:global .form-label {
  font-weight: bold;
  margin-bottom: 0;
}

:global .form-label em {
  font-weight: normal;
  font-size: 80%;
}

.form-error {
  grid-column: 1 / 7;
  color: red;
  font-size: 70%;
  margin-top: -.5em;
}

.right-pane {
  display: grid;
  grid-template-rows: 4em auto;
}

.right-pane-header {
  grid-column: 1 / 5;
}

.right-pane label {
  font-size: 70%;
  margin-bottom: 0;
}

.split-row {
  display: flex;
}

.split-row > * {
  margin: 0 .25em;
}

.split-row-disabled {
  display: flex;
  opacity: 50%;
}

.split-row-disabled > * {
  margin: 0 .25em;
}

.split-button {
  cursor: pointer;
  align-self: center;
}

.form input {
  margin-bottom: .5em;
  font-size: 90%;
  grid-column: 1 / 7;
}

.form select {
  margin-bottom: .5em;
  font-size: 90%;
}

.project-image-container {
  grid-column: 1 / 4;
}

.project-image-container img{
  width: 100%;
}

.banner-image-container {
  grid-column: 4 / 7;
  height: 251px;
}

.banner-image-container img {
  width: 100%;
}

:global .form-row {
  grid-column: 1 / 7;
}

:global .custom-file {
  margin: .5em;
}

:global .custom-file input {
  height: 1.5em;
}

:global .custom-file-label {
  font-weight: normal;
  padding: 2px 4px;
  height: 2em;
  color: #707070;
}

:global .custom-file-label::after {
  height: 1.85em;
  padding: 2px 4px;
}
.album-icon {
  color: black;
}

.overlay {
  position: fixed; 
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1); 
  z-index: 1500; 
}

.left-pane-release {
  grid-column: 1 / 6;
  grid-column-gap: 1em;
  grid-row-gap: .5em;
  padding-right: 1em;
  border-right: 1px solid #EEE;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.left-pane-release label {
  grid-column: 1 / 5;
}


.right-pane-release {
  grid-row-start: 1;
  grid-column: 6 / 12;
  padding-left: 1em;
}


.left-pane-release input {
  grid-column: 1 / 6;
}

.release-create-art {
  grid-column: 1 / 4;
  grid-row: 3 / 4;
  border: 1px solid #aaa;
}

.release-create-art img {
  width: 100%;
}

.file-input-release-art {
  grid-row: 4 / 5;
  grid-column: 1 / 4;
}



.release-price-container {
  grid-row: 5 / 6;
  grid-column: 1 / 3;
}

.release-splits {
  grid-row: 6 / 7;
  grid-column: 1 / 7;
  font-weight: bold;
}

.release-price {
  display: flex;
  justify-content: center;
}

.project-split-display {
  font-weight: normal;
}

.file-drop-container {
  grid-column: 2 / 6;
  display: flex;
  justify-content: center;
}

.enter-track-info-text {
  margin-top: .5em;
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}

.release-create-work-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: .5em;
}

.release-create-work-row > * {
  margin: 0 2em;
}

.release-create-work-row > button {
  height: 70%;
  align-self: center;
}

.release-create-work-row-header {
  margin-top: 2em;
  display: flex;
  justify-content: flex-start;
  color: #707070;
}

.release-create-work-row-header > * {
  margin: 0 6em;
}

.work-title-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.track-number {
  font-weight: bold;
  font-size: 140%;
  width: 2em;
}

.price-container {
  display: flex;
}

.dollar-sign {
  font-size: 140%;
  margin-right: .25em;
}

.release-create-work-price {
  width: 6em;
  margin-left: 0;
}

.release-create-work-price-grey {
  width: 6em;
  margin-left: 0;
  color: #888;
}

.track-button {
  cursor: pointer;
  margin: .5em;
}

.split-modal {
  z-index: 2000;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
  font-size: 80%;
  box-shadow: 2px 4px 46px -2px rgba(0,0,0,0.05);
}

.split-modal input {
  font-size: 80%;
}