/* ====== HEADER ======*/
#header .navbar .navbar-brand {
	  color: #232323;
	  font-size: 30px;
	  font-weight: 700;
}
#header .navbar .navbar-brand span {
	  color: #FF2800;
}
#header .navbar .nav-link {
	  color: #232323;
	  font-weight: 400;
	  font-size: 16px;
	  padding: 20px 10px;
}
#header .navbar-dark .navbar-toggler {
	  color: #000;
	  border-color: #000;
	  background-color: #000;
}
#header .navbar-nav {
	  align-items: center;
}
#header .navbar {
	  padding: 0 40px;
}
#header .navbar-brand {
	  padding-top: 0;
	  padding-bottom: 0;
}

#header .dropdown-toggle {
	  background: #FF2800;
	  padding: 10px;
	  border-radius: 30px;
}

#header .dropdown-toggle::after {
	  border: 0;
}

#header .dropdown-toggle {
	  color: #fff;
	  font-size: 18px;
	  font-weight: 600;
	  text-decoration: none;
}

.header-cart .cart-btn .dropdown-cart {
	  min-width: 300px;
	  left: -225px;
	  padding: 0;
}

.header-cart .cart-btn .dropdown-menu .divider {
	  height: 1px;
	  margin: 9px 0;
	  overflow: hidden;
	  background-color: #e5e5e5;
}

.header-cart .cart-btn .cart-box-title {
	  padding: 10px;
	  background-color: #EDEDED;
	  border-bottom: 1px solid #979797;
	  list-style-type: none;
}

.header-cart .cart-btn .cart-list {
	  list-style-type: none;
	  padding: 20px;
}

.header-cart .cart-btn ul.dropdown-cart li .item {
	  display: block;
	  padding: 3px 10px;
	  margin: 3px 0;
}

.header-cart .cart-btn ul.dropdown-cart li .item:after {
	  visibility: hidden;
	  display: block;
	  font-size: 0;
	  content: " ";
	  clear: both;
	  height: 0;
}

.header-cart .cart-btn ul.dropdown-cart li .item-left {
	  float: left;
}

.header-cart .cart-btn ul.dropdown-cart li .item-left img, .header-cart .cart-btn ul.dropdown-cart li .item-left span.item-info {
	  float: left;
}

.header-cart .cart-btn ul.dropdown-cart li .item-left span.item-info {
	  margin-left: 10px;
}

.header-cart .cart-btn ul.dropdown-cart li .item-left span.item-info span {
	  display: block;
}

.header-cart .cart-btn ul.dropdown-cart li .item-right {
	  float: right;
}

/* ===== FOOTER ===== */
#footer .navbar a {
	  color: #232323;
	  font-size: 14px;
}
#footer .navbar .footer-faq span {
	  padding: 20px 20px 20px 0;
}
#footer .navbar .footer-faq p {
	  margin-top: 20px;
	  vertical-align: middle;
}

a {
	color: #FF2800;
	font-size: 80%;
	font-weight: bold;
}

a:hover {
	color: #FF2800;
}