/* .background {
    min-height: 100vh;
    width: 100%;

} */

.loading-status {
    width: 32em;
    margin: 4em auto 0 auto;
}

.project-not-found {
    width: 32em;
    margin: 4em auto 0 auto;
}

.loading-status h3 {
    padding: 4em;
    text-align: center;

}

.project-not-found h3 {
    padding: 4em;
    text-align: center;
}

.background-image {
    grid-column: 1 / 8;
    z-index: -1;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1440px;
}

.store-container {
    display: grid;
    grid-template-columns: 24px 380px 160px 1fr 1fr 1fr 1fr 24px;
    grid-template-rows: 140px auto;
    grid-row-gap: 24px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
}



.project-profile {
    background: rgba(39, 40, 42, 0.8);
    display: grid;
    grid-template-rows: auto;
    color: white;
    grid-row-start: 1 / 2;
    grid-column: 2 / 4;
    min-height: 100px;
    padding: 1em;
    border-radius: 5px;
}

.project-profile-header {
    grid-column: 1 / 2;
}

.project-profile-name {
    display: inline;
    font-family: 'Open Sans', 'sans-serif';
}



.project-profile-description {
    grid-column: 1 / 2;
}

.project-image-circle {
    object-fit: cover;
    justify-self: end;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    width: 7em;
    height: 7em;
    border: 3px solid #aaa;
}

.releases-container {
    grid-row: 2 / 3;
    grid-column: 2 / 8;
    background: rgba(255,255,255,.9);
    padding: 48px;
    border: 0;
    border-bottom: 12px solid #27282a;
    border-radius: 5px;
    user-select: none;
}

.releases-header-text {
    background: #222;
    color: white;
    margin: -48px -48px 36px -48px;
    padding: 10px 6px 6px 6px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.release-name {
    cursor: default;
    align-items: bottom;
    height: auto;
}

.release-name-text {
    display: inline;

}

.release-art {
    margin-right: 18px;
    width: 60px;
    height: 60px;
}

.release-table {
    margin: 24px 0;
    justify-items: left;
}

.work-row {
    grid-column: 1 / 9;
    display: grid;
    grid-template-columns: 32px 24px 1fr 1fr 1fr 1fr 1fr 1fr;
    border-top: none;
    padding: 1px;
}

.work-row:hover {
    background: #aaa;
    color: #fff;
    cursor: pointer;
}

.td-play-button {
    grid-column: 1 / 2;
}

.play-button {
    cursor: pointer;
}

.td-work-title {
    grid-column: 3 / 4;
}

.td-cart-button {
    grid-column: 7 / 8;
}

.td-work-price {
    grid-column: 8 / 9;
}

.cart-icon {
    cursor: pointer;
}
