@charset "utf-8";
/* CSS Document */
body {
	  font-family: 'Open Sans', sans-serif;
	  color: #232323;
}
.btn-primary {
	  background-color: #FF2800;
	  border-color: #FF2800;
}
.btn {
	  font-weight: 700;
	  padding: 10px 20px;
	  font-size: 16px;
		letter-spacing: 1px;
		outline: none;
}
.btn-custom {
	  background: transparent;
	  border: 0;
	  padding: 5px;
	  cursor: pointer;
}
.btn-custom:focus {
	  outline: 0;
}
.btn-primary:hover {
	  color: #FF2800;
	  background-color: transparent;
	  border-color: #FF2800;
}
.shadow {
	  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.btn-primary.disabled, .btn-primary:disabled {
	  color: #fff;
	  background-color: #DBDBDB;
	  border-color: #DBDBDB;
}
.btn-outline-primary {
	  border-color: #FF2800;
	  color: #FF2800;
}
.btn-outline-primary:hover {
	  color: #fff;
	  background-color: #FF2800;
	  border-color: #FF2800;
}

.max {
	  max-width: 30px !important;
}

.grayout {
  opacity: 0.6;
}
/* ===== Middle Section ===== */
#fm-banner {
	  background-image: url(images/home-banner.jpg);
	  background-size: cover;
	  background-repeat: no-repeat;
	  background-position: center top;
	  height: 1420px;
}
#fm-banner .text-overlay {
	  background-color: #FF2800;
	  max-width: 800px;
	  padding: 40px 30px 40px 80px;
}
#fm-banner .text-overlay h1 {
	  font-size: 40px;
	  font-weight: 800;
	  margin-bottom: 40px;
}
#fm-banner .text-overlay p {
	  font-size: 25px;
	  font-weight: 400;
}
#fm-services .text-wrap {
	  padding: 30px;
}
#fm-services .text-wrap h2 {
	  font-size: 40px;
	  color: #FF2800;
	  font-weight: 800;
	  margin-bottom: 25px;
}
#fm-services .text-wrap p {
	  font-size: 22px;
	  color: #232323;
	  font-weight: 400;
}
#fm-services .img-wrap {
	  position: relative;
	  top: -150px;
}
#fm-services .paid-wrap {
	  padding-top: 30px;
	  padding-bottom: 300px;
}
#fm-create-account {
	  background-image: url(images/create-account-banner.jpg);
	  background-size: cover;
	  background-repeat: no-repeat;
	  background-position: center top;
	  height: 1314px;
	  position: relative;
}
#fm-create-account .text-overlay {
	  background-color: #FF2800;
	  max-width: 800px;
	  padding: 40px 30px 40px 80px;
	  position: absolute;
	  top: -100px;
	  right: 0;
}
#fm-create-account .text-overlay h1 {
	  font-size: 40px;
	  font-weight: 800;
	  margin-bottom: 40px;
}
#fm-create-account .text-overlay p {
	  font-size: 25px;
	  font-weight: 400;
}
#fm-create-account .text-inner h2 {
	  font-size: 80px;
	  font-weight: 800;
	  margin: 30px 20px 90px;
}
.line {
	  height: 10px;
	  background-color: #FF2800;
}
/* ===== FOOTER ===== */
#footer .navbar a {
	  color: #232323;
	  font-size: 14px;
}
#footer .navbar .footer-faq span {
	  padding: 20px 20px 20px 0;
}
#footer .navbar .footer-faq p {
	  margin-top: 20px;
	  vertical-align: middle;
}
/* ===== LOGIN AND CREATE ACCOUNT PAGE ===== */
.middle-wrap {
	  background-color: #F5F5F5;
	  padding-top: 120px;
	  padding-bottom: 120px;
}
#fm-login .login-inner {
	  background-image: url(images/login-bg.jpg);
	  background-repeat: no-repeat;
	  background-position: center top;
	  background-size: cover;
	  padding: 107px 50px;
}
#fm-login .signup-inner {
	  padding: 27px 50px;
}
#fm-login .login-wrapper .controls input {
	  width: 100%;
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  padding: 10px;
}
#fm-login .login-wrapper h3 {
	  font-size: 24px;
	  font-weight: 700;
}
#fm-login .login-wrapper p {
	  font-size: 14px;
	  font-weight: 500;
}
#fm-login .login-wrapper label {
	  font-size: 14px;
	  margin-top: 5px;
}
#fm-login .login-wrapper .checkbox input {
	  margin-top: 20px;
	  margin-bottom: 20px;
	  width: auto;
}
#fm-login .login-wrapper .checkbox {
	  color: #fff;
	  font-size: 12px;
}
#fm-login .login-wrapper .checkbox a {
	  color: #fff;
	  font-size: 12px;
	  font-weight: 600px;
	  text-decoration: underline;
}
#fm-login .login-wrapper .btn {
	  width: 85%;
	  margin: 0 auto;
	  display: block;
}
#fm-login .login-wrapper .signup-inner h3 {
	  color: #B1B1B1;
}
#fm-login .login-wrapper .signup-inner p {
	  color: #4A4A4A;
}
#fm-login .login-wrapper .signup-inner label {
	  color: #4A4A4A;
}
#fm-login .login-wrapper .signup-inner .checkbox {
	  color: #232323;
}
#fm-login .login-wrapper .signup-inner .checkbox a {
	  color: #232323;
	  text-decoration: underline;
}
/* ===== CONTACT US PAGE ===== */
#fm-contact-us .contact-us-inner {
	  padding: 100px;
}
.contact-us-inner .title h2 {
	  font-size: 24px;
	  font-weight: 700;
}
.contact-us-inner .form-group label {
	  font-size: 14px;
}
.contact-us-inner .form-group .form-control {
	  font-size: 14px;
}
/* ===== PRIVACY POLICY PAGE ===== */
#fm-privacy-policy .privact-policy-wrap h3 {
	  font-size: 24px;
	  font-weight: 700;
	  margin-bottom: 30px;
}
#fm-privacy-policy .privact-policy-wrap p {
	  font-size: 14px;
	  line-height: 25px;
	  margin-bottom: 30px;
}
#fm-privacy-policy .privact-policy-wrap h4 {
	  font-size: 18px;
	  font-weight: 600;
	  margin-bottom: 30px;
}
#fm-privacy-policy .privact-policy-wrap a {
	  font-size: 16px;
	  font-weight: 700;
	  color: #008FFF;
	  text-decoration: underline;
	  margin-bottom: 30px;
	  display: block;
}
#fm-privacy-policy .privact-policy-wrap .contact-link {
	  text-decoration: underline;
	  display: inline-block;
	  color: #232323;
	  font-weight: 400;
}
/* ===== FAQ PAGE ===== */
.mb-0 > a {
	  display: block;
	  position: relative;
}
.mb-0 > a:after {
	  content: "\f078"; /* fa-chevron-down */
	  font-family: 'FontAwesome';
	  position: absolute;
	  right: 0;
}
.mb-0 > a[aria-expanded="true"]:after {
	  content: "\f077"; /* fa-chevron-up */
}
.card-header {
	  border-bottom: 0;
}
#accordion .card .show {
	  background-color: #EDEDED;
}
.card-header {
	  background-color: #EDEDED;
}
#fm-faq .title h2 {
	  font-size: 30px;
}
#accordion .card .card-header h5 a {
	  font-size: 18px;
	  font-weight: 700;
	  color: #232323;
	  text-decoration: none;
}
#accordion .collapse .card-body {
	  font-size: 14px;
}


/* ===== CHECKOUT ===== */
/* Customer info */
#fm-checkout .checkout-wrap .nav-tabs .btn {
	  background-color: transparent;
	  padding: 10px 113px;
	  font-size: 14px;
	  font-weight: 700;
	  color: #B1B1B1;
}
#fm-checkout .checkout-wrap .btn:focus {
	  box-shadow: none;
}
#fm-checkout .checkout-wrap .progress {
	  height: 7px;
}
#fm-checkout .checkout-wrap .progress-bar {
	  background-color: #FF2800;
}
#fm-checkout .tab-content .login-wrapper h3 {
	  font-size: 24px;
	  color: #B1B1B1;
	  font-weight: 700;
}
#fm-checkout .tab-content .login-wrapper p {
	  font-size: 14px;
	  font-weight: 500;
}
#fm-checkout .tab-content .login-wrapper .control-group label {
	  font-size: 14px;
}
#fm-checkout .tab-content .login-wrapper .control-group input {
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  width: 100%;
	  padding: 10px;
}
#fm-checkout .tab-content .login-wrapper .control-group .checkbox input {
	  width: auto;
}
#fm-checkout .tab-content .login-wrapper .control-group .checkbox {
	  font-size: 12px;
	  margin-top: 15px;
	  margin-bottom: 20px;
}
#fm-checkout .tab-content .login-wrapper .control-group .checkbox a {
	  font-weight: 600;
	  color: #232323;
	  text-decoration: underline;
}
#fm-checkout .login-wrapper .btn {
	  width: 85%;
	  margin: 0 auto;
	  display: block;
}
h1 {
	  display: inline-block!important;
}
.text-center {
	  text-align: center;
}
.tab-pane {
	  padding: 30px 0;/* height: 250px; */
}
/* Checkout */
#fm-checkout .checkout-inner .title h6 {
	  font-size: 14px;
	  color: #868686;
}
.checkout-inner .checkout-detail-wrap .artist-name {
	  width: 40%;
}
.checkout-inner .checkout-detail-wrap .video-id {
	  width: 25%;
}
.checkout-inner .checkout-detail-wrap .channel {
	  width: 25%;
}
.checkout-inner .checkout-detail-wrap .price {
	  width: 7%;
}
.checkout-inner .checkout-detail-wrap .remove-item {
	  width: 3%;
}
.checkout-inner .checkout-detail-wrap .artist-name h5 {
	  display: inline-block;
}
#fm-checkout .confirmation-wrap {
	  padding: 90px;
}
#fm-checkout .confirmation-wrap .download-wrap {
	  border-top: 2px dashed #DBDBDB;
	  border-bottom: 2px dashed #DBDBDB;
}
.checkout-inner .checkout-detail-wrap {
	  padding-top: 30px;
	  padding-bottom: 70px;
}
.checkout-detail-inner .artist-name span {
	  font-size: 16px;
	  font-weight: 400;
}
.checkout-detail-inner .artist-name h5 {
	  font-size: 16px;
	  font-weight: 400;
}
.checkout-detail-inner .video-id input {
	  padding: 5px 10px;
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  width: 85%;
	  color: #898989;
}
.checkout-detail-inner .channel input {
	  padding: 5px 10px;
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  width: 85%;
	  color: #898989;
}
.checkout-detail-inner .price p {
	  font-size: 16px;
	  font-weight: 500;
}
.checkout-inner .total-wrap .text-dec p {
	  font-size: 12px;
	  color: #868686;
	  font-weight: 400;
}
.checkout-inner .price-wrap .sub-total, .text {
	  font-size: 16px;
	  font-weight: 400;
	  color: #969696;
}
.checkout-inner .price-wrap .total {
	  font-size: 16px;
	  font-weight: 600;
}
/* Confirmation */
.confirmation-wrap .payment-title span {
	  font-size: 16px;
	  font-weight: 600;
	  color: #868686;
	  text-transform: uppercase;
	  padding-bottom: 15px;
	  display: block;
}
.confirmation-wrap .payment-title h3 {
	  font-size: 24px;
	  font-weight: 700;
}
.confirmation-wrap .payment-title p {
	  font-size: 16px;
}
.confirmation-wrap .download-wrap .licenses-cost {
	  display: table-cell;
}
.confirmation-wrap .download-wrap .licenses-cost-inner {
	  display: table-cell;
	  vertical-align: middle;
}
.confirmation-wrap .download-wrap .licenses-cost p {
	  font-size: 16px;
	  font-weight: 500;
	  color: #B1B1B1;
	  margin-bottom: 0;
}
.confirmation-wrap .download-wrap .licenses-cost span {
	  font-size: 30px;
	  font-weight: 700;
}
/*.confirmation-wrap .download-wrap .btn {
	background-color: transparent;
	border-color: #FF2800;
	color: #FF2800;
	width: 100%;
}*/
.confirmation-wrap .invoice-id span {
	  font-size: 14px;
	  color: #868686;
}
.confirmation-wrap .invoice-id .id {
	  font-family: 'Lato', sans-serif;
	  font-weight: 700;
}
/* ===== MYDESHBOARD LICENSES  ===== */
/*#fm-dashboard-licenses .table-download tr {
	margin: 10px;
}*/
#fm-dashboard-licenses .table-download tr td input {
	  padding: 5px 20px;
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  max-width: 85%;
}
#fm-dashboard-licenses .table-download tr td:nth-child(7) {
	  color: #898989;
	  font-weight: 300;
	  font-style: italic;
}
#fm-dashboard-licenses .table-download tr td:nth-child(8):hover {
	  background-color: #EDEDED;
	  border-radius: 10px;
}
.dashboard-inner .back {
	  font-size: 16px;
	  font-weight: 700;
	  color: #008FFF;
	  text-decoration: underline;
	  margin-bottom: 30px;
	  display: block;
}
.profile-wrap .text-wrap {
	  padding: 20px 20px 0 20px;
}
/*#fm-dashboard-licenses .box:hover {
	border-bottom: 4px solid #12DAD1;
}*/
/* ===== MYDESHBOARD CATALOG  ===== */
#fm-dashboard-catalog .catalog-wrap {
	  padding-bottom: 170px;
}
#fm-dashboard-catalog .catalog-wrap h1 {
	  font-size: 48px;
	  color: #B1B1B1;
	  font-weight: 400;
}
/*#fm-dashboard-catalog .catalog-wrap .btn {
	background-color: transparent;
	border-color: #FF2800;
	color: #FF2800;
	margin-top: 50px;
}*/
/* ===== Roster Create an Artist  ===== */
.create-artist-form h3 {
	  font-size: 26px;
	  font-weight: 600;
}
.create-artist-form .form-inner .form-group label {
	  font-size: 14px;
	  font-weight: 400;
	  text-align: right;
}
.create-artist-form .form-group h6 {
	  font-size: 16px;
	  font-weight: 700;
}
.create-artist-form .form-group h6 span {
	  font-size: 14px;
	  font-weight: 400;
	  color: #868686;
	  margin-left: 10px;
}
.create-artist-form .form-group .subscribe span {
	  font-size: 14px;
	  font-weight: 400;
}
.create-artist-form .form-group .subscribe .sub-label {
	  font-size: 14px;
	  font-weight: 700;
	  margin-right: 10px;
}
/* ===== catalog-add-wrap ===== */
.right-line {
	  width: 85%;
	  height: 1px;
	  background-color: #DBDBDB;
	  display: inline-block;
	  margin-left: 35px;
	  vertical-align: middle;
}
.catalog-add-wrap .add-catlog-box {
	  border: 2px dashed #979797;
	  height: 318px;
	  max-width: 250px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  flex-direction: column;
}
.catalog-add-wrap p {
	  font-size: 16px;
	  color: #868686;
	  font-weight: 600;
	  margin-bottom: 30px;
}
.catalog-add-wrap .add-catlog-box p {
	  font-size: 20px;
	  font-weight: 500;
	  margin-top: 10px;
}
.catalog-add-wrap .added-catlog-box p {
	  font-size: 16px;
	  font-weight: 700;
	  color: #232323;
	  padding: 20px;
}
/* ===== ADD ALBUM ===== */
.add-album-wrap .title h3 {
	  font-size: 26px;
	  font-weight: 600;
	  color: #232323;
}
.add-album-wrap .title p {
	  font-size: 16px;
	  font-weight: 700;
	  color: #232323;
}
.add-album-wrap .title h6 {
	  font-size: 16px;
	  font-weight: 600;
	  color: #232323;
}
.add-album-wrap .pricing-table .pricing-inner p span {
	  font-size: 14px;
	  font-weight: 700;
	  margin-right: 20px;
}
.add-album-wrap .pricing-table .pricing-inner p {
	  font-size: 14px;
	  font-weight: 400;
}
.add-album-wrap .price-box a {
	  font-size: 14px;
	  font-weight: 600;
	  text-decoration: underline;
	  color: #008FFF;
	  display: block;
}
.add-album-wrap .price-box input {
	  border: 1px solid #DBDBDB;
	  border-radius: 5px;
	  max-width: 80px;
	  padding: 5px 10px;
	  margin-bottom: 5px;
	  text-align: right;
}
.add-album-wrap .price-box span {
	  font-size: 14px;
	  font-weight: 400;
}
.add-album-wrap .pricing-table .more-info {
	  font-size: 16px;
	  font-weight: 600;
}
.add-album-wrap .pricing-table .more-info a {
	  color: #008FFF;
	  text-decoration: underline;
}
.add-album-wrap .album-detail .form-group label {
	  font-size: 14px;
	  font-weight: 600;
}
.add-album-wrap .album-detail .form-group input {
	  border-color: #DBDBDB;
}
.add-album-wrap .album-detail a {
	  font-size: 14px;
	  font-weight: 600;
	  color: #008FFF;
	  text-decoration: underline;
}
.add-album-wrap .album-detail .file-uploader .custom-file {
	  height: calc(11.25rem + 2px);
}
.add-album-wrap .album-detail .file-uploader .custom-file-input {
	  width: 200px;
	  height: calc(11.25rem + 2px);
	  background: transparent;
}
.add-album-wrap .album-detail .file-uploader .custom-file-label {
	  background-color: #fff;
	  border: 2px dashed #ced4da;
	  border-radius: .25rem;
	  width: 200px;
	  height: calc(11.25rem + 2px);
}
.add-album-wrap .album-detail .file-uploader .custom-file-label::after {
    height: calc(calc(11.25rem + 2px) - 1px * 2);
	  color: #495057;
	  content: " ";
	  background-color: transparent;
	  border-left: 0;
	  border-radius: 0;
	  width: 100%;
}
.add-album-wrap .album-detail .file-uploader .custom-file-label a {
	  text-decoration: none;
	  color: #008FFF;
	  font-size: 16px;
	  font-weight: 600;
}
.add-album-wrap .pricing-table .download-dec {
	  font-size: 16px;
	  font-weight: 600;
}
.add-album-wrap .pricing-table .download-dec a {
	  color: #008FFF;
}
/* Model */
.modal-dialog {
	  max-width: 1050px;
}
.modal-header {
	  border-bottom: 0;
	  padding: 10px 15px 0;
}
.new-track .modal-title {
	  font-size: 30px;
	  font-weight: 400;
	  color: #FF2800;
	  margin-bottom: 15px;
}
.new-track .album-name h3 {
	  font-size: 26px;
	  font-weight: 600;
	  margin-bottom: 20px;
}
.new-track .album-name h3 span {
	  font-size: 16px;
	  font-weight: 700;
	  margin-left: 30px;
}
.new-track .album-name .form-group label {
	  font-size: 14px;
	  font-weight: 400;
	  margin-right: 15px;
}
.new-track .album-name .form-group input {
	  display: inline-block;
	  width: 80%;
}
.new-track .file-uploader .custom-file-label p {
	  font-size: 20px;
	  font-weight: 600;
	  color: #868686;
	  margin-bottom: 0;
}
.new-track .file-uploader .custom-file-label span {
	  font-size: 15px;
	  font-weight: 400;
	  color: #868686;
	  margin-bottom: 0;
}
.file-uploader .custom-file {
	  height: calc(6.25rem + 2px);
}
.file-uploader .custom-file-input {
	  width: 100%;
	  height: calc(6.25rem + 2px);
	  background: transparent;
}
.file-uploader .custom-file-label {
	  background-color: #fff;
	  border: 2px dashed #ced4da;
	  border-radius: .25rem;
	  width: 100%;
	  height: calc(6.25rem + 2px);
}
.file-uploader .custom-file-label::after {
    height: calc(calc(6.25rem + 2px) - 1px * 2);
	  color: #495057;
	  content: " ";
	  background-color: transparent;
	  border-left: 0;
	  border-radius: 0;
	  width: 100%;
}
.file-uploader .custom-file-label a {
	  text-decoration: none;
	  color: #008FFF;
	  font-size: 16px;
	  font-weight: 600;
}
.new-track .create-splits h3 {
	  font-size: 26px;
	  font-weight: 600;
	  display: inline-block;
}
.new-track .create-splits .add-user-panel label {
	  font-size: 14px;
	  font-weight: 400;
}
.create-track-wrap input {
	  color: #232323;
	  border: 1px solid #DBDBDB;
}
.create-track-wrap .upload-song {
	  padding: 0 20px 20px;
}
.new-track-inner {
	  padding: 0 20px;
}
.create-track-wrap .upload-song h6 {
	  font-size: 14px;
	  font-weight: 400;
}
/* ===== ROSTER ===== */
#fm-dashboard-licenses .roster th {
	  font-size: 20px;
	  font-weight: 400;
	  color: #868686;
}
#fm-dashboard-licenses .roster .name {
	  font-size: 26px;
	  font-weight: 600;
}
#fm-dashboard-licenses .roster .count {
	  font-family: 'Lato', sans-serif;
	  font-weight: 700;
	  color: #868686;
	  font-size: 20px;
}
#fm-dashboard-licenses .roster .download {
	  font-family: 'Lato', sans-serif;
	  font-weight: 700;
	  color: #868686;
	  font-size: 20px;
}
#fm-dashboard-licenses .roster .revenue {
	  font-family: 'Lato', sans-serif;
	  font-weight: 700;
	  color: #868686;
	  font-size: 20px;
}
#fm-dashboard-licenses .roster .link {
	  color: #868686;
	  font-size: 20px;
	  font-weight: 400;
}
#fm-dashboard-licenses .roster .id input {
	  border: 1px solid #979797;
	  padding: 10px;
	  border-radius: 5px;
	  font-size: 16px;
	  font-weight: 400;
}
/* ===== REPORTS ===== */
.my-reports-wrap .box span {
	  font-size: 14px;
	  font-weight: 600;
	  margin-bottom: 10px;
	  display: block;
}
.my-reports-wrap .box h3 {
	  font-family: 'Lato', sans-serif;
	  font-size: 40px;
	  font-weight: 700;
}
.my-reports-wrap .box h5 {
	  font-family: 'Lato', sans-serif;
	  font-size: 24px;
	  font-weight: 700;
}
.my-reports-wrap .box p {
	  font-size: 14px;
	  color: #868686;
	  font-weight: 400;
	  margin-bottom: 0;
}
.my-reports-wrap .box p span {
	  font-family: 'Lato', sans-serif;
	  font-size: 14px;
	  font-weight: 700;
	  color: #12DAD1;
	  display: inline-block;
	  margin-right: 8px;
	  margin-bottom: 0;
}
.my-reports-wrap .box p a {
	  text-decoration: underline;
	  color: #008FFF;
}
.revenue-chart .chart-top-panel span {
	  font-size: 20px;
	  font-weight: 700;
}
.revenue-chart .chart-top-panel .form-group label {
	  font-size: 14px;
	  font-weight: 400;
}
.revenue-chart .chart-top-panel .average p {
	  font-size: 14px;
	  font-weight: 700;
	  color: #868686;
	  margin-bottom: 0;
}
.revenue-chart .chart-top-panel .average span {
	  font-family: 'Lato', sans-serif;
	  font-size: 40px;
	  font-weight: 700;
	  color: #FF2800;
}
.my-reports-wrap .video-wrap .title {
	  font-size: 20px;
	  font-weight: 700;
	  color: #242424;
}
.my-reports-wrap .video-wrap .video-box h6 {
	  font-size: 16px;
	  font-weight: 700;
	  margin: 5px 0;
}
.my-reports-wrap .video-wrap .video-box p {
	  font-size: 14px;
	  font-weight: 400;
	  color: #868686;
	  margin-bottom: 0;
}
.my-reports-wrap .video-wrap .video-box .views {
	  font-family: 'Lato', sans-serif;
}
.recently-viewed .list label {
	  font-size: 14px;
	  font-weight: 400;
}
.col-container {
	  display: table;
	  width: 100%;
}
.cal {
	  display: table-cell;
	  padding: 16px;
}

/* ===== MEDIA QUERY ===== */
@media (min-width:768px) and (max-width:991px) {
    #fm-services .paid-wrap {
	      padding-top: 30px;
	      padding-bottom: 120px;
    }
    .confirmation-wrap .download-wrap .licenses-cost {
	      display: block;
    }
    .confirmation-wrap .download-wrap .btn {
	      width: auto;
    }
}

@media (max-width:767px) {
    #header .navbar {
	      padding: 15px;
    }
    .header-cart .dropdown-toggle {
	      margin: 10px;
	      display: block;
    }
    #fm-services .img-wrap {
	      position: static;
    }
    #fm-create-account .text-overlay {
	      position: static;
    }
    #middle-wrap .login-inner {
	      padding: 50px 20px;
    }
    #middle-wrap .signup-inner {
	      padding: 72px 20px;
    }
    #middle-wrap {
	      padding-top: 30px;
	      padding-bottom: 30px;
    }
    #fm-contact-us .contact-us-inner {
	      padding: 30px;
    }
    #fm-services .paid-wrap {
	      padding-top: 30px;
	      padding-bottom: 30px;
    }
    #fm-create-account .text-inner h2 {
	      font-size: 60px;
    }
    #fm-artist-banner .song-play {
	      position: static;
    }
    #fm-artist-banner .songs-wrap {
	      padding-top: 100px;
    }
    .profile-wrap .profile {
	      position: static;
    }
    .profile-wrap {
	      padding-top: 20px;
    }
    .btn {
	      padding: 10px 40px;
    }
    #fm-checkout .confirmation-wrap {
	      padding: 0;
    }
    .login-wrapper .signup-inner {
	      padding: 0 !important;
    }
    .login-wrapper .login-inner {
	      padding: 0 !important;
    }
    #fm-checkout .checkout-wrap .nav-tabs .btn {
	      padding: 10px 40px;
    }
    #fm-checkout .confirmation-wrap .download-wrap {
	      padding: 20px 0!important;
    }
    .checkout-inner .checkout-detail-wrap .artist-name {
	      width: 100%;
    }
    .checkout-inner .checkout-detail-wrap .video-id {
	      width: 100%;
    }
    .checkout-inner .checkout-detail-wrap .channel {
	      width: 100%;
    }
    .checkout-inner .checkout-detail-wrap .price {
	      width: 100%;
    }
    .checkout-inner .checkout-detail-wrap .remove-item {
	      width: 100%;
    }
    .file-uploader .custom-file-label {
	      height: auto;
    }
    .add-user-panel input {
	      width: 100% !important;
    }
    .form-inline .form-check {
	      width: 23%;
    }
}
