.admin-container {
  display: grid;
	grid-template-columns: 4em 1fr 1fr 1fr 1fr 4em;
	grid-template-rows: 5.75em auto;
	grid-column-gap: 1em;
	grid-row-gap: 1em;
	margin-top: 1.5em; 
	min-width: 70%;
	max-width: 1280px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding: 2em;
}

.container-title {
	grid-row: 1 / 2;
	grid-column: 1 / 7;
	padding: 1.5em;
	background-color: #235789;
	color: white;
}

.admin-body {
	grid-column: 1 / 7;
}

.admin-body h3 {
	border-bottom: 1px solid #ccc;
	margin-bottom: 2em;
}
